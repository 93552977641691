$base-font: 'Circle', sans-serif;
$text-color: #685c5c;
$text-color-red: #f27a86;
$button-bg-color: $text-color-red;
$button-bg-hover-color: #f2586f;

body * {
  box-sizing: border-box;
}

body {
  font-family: $base-font;
  font-size: 18px;
  font-weight: 400;
  color: $text-color;
  background-color: #ffffff;
  margin: 0;
  line-height: 1.2;
}

strong {
  font-weight: 700;
}

.text-red {
  color: $text-color-red;
}

.button {
  display: block;
  width: 270px;
  height: 72px;
  line-height: 72px;
  border-radius: 36px;
  background-color: $button-bg-color;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  transition: .3s;
  &:hover {
    background-color: $button-bg-hover-color;
  }
}

.mobile-only {
  display: none;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 10px;
}

.title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  line-height: 1;

  span {
    font-style: italic;
  }
}

.header {
  background: #f5eaf2;
}

.section-01 {
  position: relative;

  .top-block {
    display: flex;
    padding: 80px 0 0;
  }

  .info-block {
    margin-left: -15px;
    max-width: 480px;
    padding-top: 83px;
  }

  .info-inner {
    font-weight: 700;
    font-size: 24px;
    padding: 45px 15px;

    span {
      color: $text-color-red
    }
  }

  .image-block {
    position: relative;

    .author-image {
      display: block;
    }

    .top {
      right: 53px;
      position: absolute;
      top: 27px;
      transform: rotate(-7deg);
      width: 103px;
    }

    .bottom {
      left: 75px;
      position: absolute;
      top: 215px;
      width: 121px;
    }
  }

  .date-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 45px;

    .item {
      margin-right: 15px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 35px;
      }

      img {
        display: inline-block;
        margin-right: 6px;
      }
    }
  }

  .bottom-block {
    background: url("../img/header-bt-bg.png") no-repeat center;
    width: 553px;
    height: 126px;
    padding: 29px 97px;
    text-align: center;
    position: absolute;
    bottom: -62px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-style: italic;
    line-height: 1;
    -webkit-filter: drop-shadow(2px 15px 9px rgba(0, 0, 0, .25));
    filter: drop-shadow(2px 15px 9px rgba(0, 0, 0, .25));
  }
}

.svg-shadow {
  -webkit-filter: drop-shadow(2px 7px 9px rgba(242, 88, 111, .7));
  filter:drop-shadow(2px 7px 9px rgba(242, 88, 111, .7));
}

.main-title {
  font-size: 72px;
  font-weight: 700;
  line-height: 60px;

  span {
    font-style: italic;
    color: $text-color-red;
    display: block;
    margin-left: 25px;
  }
}

.section-02 {
  padding-top: 129px;
  padding-bottom: 140px;

  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px;

    .item {
      & {
        width: 30%;
        max-width: 210px;
      }

      &-icon {
        width: 187px;
        height: 186px;
      }

      &-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 20px;
      }

      &-info {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .second {
    margin-top: 90px;
  }

  .secondary-list {
    display: flex;
    justify-content: space-between;
    max-width: 706px;
    margin: 8px auto 80px;

    .item {
      width: 45%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-icon {
        width: 204px;
        height: 152px;
        margin-bottom: 24px;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.lana-del-block {
  max-width: 886px;
  background: url("../img/lana-block-bg.jpg") no-repeat center;
  background-size: cover;
  min-height: 549px;
  padding: 70px 65px 50px;
  box-shadow: 3px 14px 24px #e8d0b4;
  position: relative;
  border-radius: 20px;
  margin-bottom: -440px;

  .cake {
    position: absolute;
    left: 36%;
    top: -42px;
    transform: rotate(-7deg);
    width: 103px;
  }

  .lana-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .inner-info {
    max-width: 320px;
    position: relative;

    &:after {
      content: 'Лана Дель Рей';
      position: absolute;
      bottom: 0;
      right: -145px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      display: inline-block;
    }
  }

  .subtitle {
    font-size: 36px;
    font-weight: 700;
    font-style: italic;
    line-height: 36px;
    margin-bottom: 35px;
  }

  .info {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 305px;
    margin-bottom: 30px;
  }
}

.section-03 {
  background-color: #f5eaf2;
  padding-top: 375px;
  padding-bottom: 90px;

  .title {
    text-align: left;
    margin-bottom: -30px;

    span {
      font-style: normal;
    }

    > span {
      display: block;
    }
  }

  .list-info {
    background: url("../img/bg-03.png") no-repeat center;
    background-size: contain;
    min-height: 610px;
    max-width: 844px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
  }

  .info-column {
    padding-top: 110px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button {
    margin: 0 auto;
  }
}

.about-course {
  margin-top: 40px;
  margin-bottom: 114px;

  .course-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .course-column {
    position: relative;
    width: 49%;

    &.img {
      max-width: 414px;
      height: 280px;
    }

    .main-img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .subtitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    padding-left: 14px;
    padding-top: 20px;
  }

  .info {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 45px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -8px;
      left: 5px;
      filter: drop-shadow( 2px 4px 3px rgba(242, 88, 111, .7));

    }
  }

  .cake {
    right: 0;
    position: absolute;
    top: -35px;
    width: 85px;
  }

  .cake-2 {
    right: 11px;
    position: absolute;
    top: -41px;
    width: 90px;
  }
}

.section-04 {
  padding-top: 84px;

  .title {
    text-align: left;
  }
}

.imagine-block {
  max-width: 911px;
  min-height: 795px;
  background: url("../img/povar-bg.png") no-repeat center;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 29px;

  .inner-block {
    padding: 65px 40px 95px 90px
  }

  .info-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 45px;
    margin-bottom: 30px;
    max-width: 340px;

    &:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -8px;
      left: 5px;
      filter:drop-shadow( 2px 4px 3px rgba(242, 88, 111, .7));;
    }
  }

  .bottom-info {
    font-size: 24px;
    line-height: 1;
    max-width: 411px;
    margin-bottom: 30px;
  }

  .title {
    text-align: left;
    margin-bottom: 28px;
  }

  .button {
    width: 305px;
  }

  .cake {
    left: -31px;
    position: absolute;
    top: 256px;
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
    width: 94px;
  }

  .cake-2 {
    right: -40px;
    position: absolute;
    bottom: 204px;
    width: 103px;
  }
}

.process-block {
  display: flex;

  .image {
    margin-left: -100px;
  }

  .info-block {
    margin-left: -50px;
    padding-top: 55px;
  }

  .subtitle {
    max-width: 429px;
    font-size: 47px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 30px;
  }

  .info-text {
    max-width: 390px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 35px;

    span {
      font-weight: 700;
      font-style: italic;
    }
  }

  .button {
    width: 305px;
  }
}

.section-05 {
  background-color: #f5eaf2;
  padding-top: 68px;
  padding-bottom: 50px;

  .title {
    text-align: left;
    max-width: 840px;
    margin: 0 auto 50px;
  }
}

.format {
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 840px;
    margin: 0 auto 50px;
  }

  &-item {
    width: 48%;
    margin-bottom: 45px;
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 20px;
  }

  &-image {
    text-align: center;
    min-height: 185px;
    margin-bottom: 10px;
  }

  &-info {
    line-height: 24px;
  }
}

.certificate-block {
  max-width: 889px;
  min-height: 358px;
  border-radius: 20px;
  background-color: #edf2f6;
  display: flex;
  align-items: center;
  padding: 57px 57px 6px;
  justify-content: space-between;
  margin-bottom: -225px;

  .info-text {
    max-width: 313px;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-top: -62px;

    span {
      font-style: italic;
    }
  }
}

.section-06 {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 250px;

  .title {
    text-align: left;
    max-width: 862px;
    margin: 0 auto;
  }
}

.master {
  &-class {
    display: flex;
    justify-content: space-between;
    max-width: 862px;
    margin: 80px auto 135px;
  }

  &-item {
    width: 31%;
    max-width: 215px;
  }

  &-image {
    min-height: 173px;
    margin-bottom: 15px;
    text-align: center;
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 25px;
  }

  &-text {
    line-height: 24px;
  }
}

.package {
  &-block {
    display: flex;
    min-height: 200px;
    border-radius: 20px;
    background-color: #edf2f6;
    justify-content: space-between;
    max-width: 886px;
    margin: 0 auto;
    padding: 50px 75px;
    position: relative;
    align-items: center;

    .cake {
      width: 102px;
      position: absolute;
      bottom: -33px;
      left: 50%;
      transform: translateX(-50%);
    }

    .button {
      width: 294px;
    }
  }

  &-text {
    max-width: 325px;
    font-size: 24px;
    line-height: 1;
  }
}

.section-07 {
  margin-top: 70px;

  .title {
    text-align: left;
    margin-bottom: 15px;
  }
}

.lessons-list {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 125px;

  .lessons-title {
    display: flex;
    align-items: center;
    margin-top: 47px;

    &.big-text {
      align-items: flex-start;
      flex-direction: column;

      .counter {
        margin-bottom: 16px;
      }

      .text {
        margin: 0;
      }
    }
  }

  .counter {
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    background-color: #fdc878;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
  }

  .lessons-item {
    max-width: 380px;
    width: 49%;

    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:nth-child(4) {
      order: 10;
    }

    &:nth-child(5) {
      order: 2;
    }

    &:nth-child(6) {
      order: 4;
    }
    &:nth-child(7) {
      order: 6;
    }

    &:nth-child(8) {
      order: 12;
    }

  }

  .text {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 15px;
    display: inline-block;

    .small {
      font-weight: 400;
    }
  }

  .inner-list > li {
    position: relative;
    padding-left: 40px;
    margin-top: 30px;
    line-height: 24px;
    max-width: 370px;

    &:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -10px;
      left: -2px;
      filter: drop-shadow( 2px 4px 3px rgba(242, 88, 111, .7));
    }
  }
}

.section-08 {
  background-color: #f5eaf2;
  padding-top: 75px;
  padding-bottom: 175px;
  position: relative;

  .top-cake {
    width: 102px;
    position: absolute;
    top: -33px;
    left: 55%;
    transform: translateX(-50%);
  }

  .title {
    text-align: left;
    margin-bottom: 55px;
  }
}

.bonus-list {
  display: flex;
  max-width: 800px;
  justify-content: space-between;

  .subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .inner-list > li {
    position: relative;
    padding-left: 36px;
    margin-bottom: 15px;
    line-height: 24px;
    max-width: 340px;

    &:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -10px;
      left: -7px;
      filter: drop-shadow( 2px 4px 3px rgba(242, 88, 111, .7));
    }
  }
}

.present-block {
  max-width: 889px;
  min-height: 258px;
  border-radius: 20px;
  background-color: #edf2f6;
  margin: 25px auto -300px;
  display: flex;
  align-items: center;
  padding: 35px 50px 50px;
  justify-content: space-between;

  .inner-text {
    max-width: 328px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }

  .button {
    width: 238px;
  }
}

.section-09 {
  padding-top: 60px;
  padding-bottom: 60px;

  .title {
    text-align: left;
    margin-bottom: 78px;
  }

  .button {
    width: 305px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.maker {
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 850px;
    margin: 0 auto;
  }

  &-item {
    width: 30%;
    max-width: 220px;
    margin-bottom: 75px;
    padding-right: 20px;
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin: 25px 0 20px;
  }

  &-text {
    line-height: 24px;
  }

  &-image {
    border-radius: 25px;
    width: 200px;
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.section-10 {
  padding-top: 70px;
  position: relative;
  padding-bottom: 50px;

  .title {
    text-align: left;
    margin-bottom: 44px;
  }

  .cake-1 {
    right: 20%;
    position: absolute;
    top: 27px;
    transform: rotate(-7deg);
    width: 103px;
  }
}

.section-10 {
  .table {
    .row {
      display: flex;
      justify-content: space-between;
    }

    .row.head .col {
      text-align: center;
      padding: 0 10px 5px;

      div {
        padding: 30px 0;
        border-radius: 20px 20px 0 0;
      }

      &.st div {
        background-color: #edf2f6;
      }

      &.bz div {
        background-color: #f5eaf2;
      }

      &.pr div {
        background-color: #f7dfc3;
      }
    }

    .row.foot .col {
      text-align: center;
      padding: 0 10px 5px;

      div {
        padding: 30px 0;
        border-radius: 0 0 20px 20px;
      }

      &.st div {
        background-color: #edf2f6;
      }

      &.bz div {
        background-color: #f5eaf2;
      }

      &.pr div {
        background-color: #f7dfc3;
      }
    }

    .row.body .col {
      text-align: center;
      padding: 5px 10px;

      &.big {
        text-align: left;
      }

      div {
        height: 100%;
        min-height: 148px;
        line-height: 148px;
      }

      &.st div {
        background-color: rgba(237, 242, 246, 0.15)
      }

      &.bz div {
        background-color: rgba(245, 234, 242, 0.15)
      }

      &.pr div {
        background-color: rgba(247, 223, 195, 0.15)
      }

      .table-txt-cen {
        position: absolute;
        width: 200%;
        left: -100px;
        top: 40%;
      }

      .txt-2-col {
        position: absolute;
        width: 100%;
        left: -100px;
        top: 43%;
      }
    }

    .col {
      width: 25%;
      position: relative;

      &.big {
        width: 219px;
      }
    }

    .button {
      width: 100%;
    }

    .row.body.buttons {
      .col div {
        background: transparent;
        min-height: 0;
      }
    }
  }
}

.table-mobile  {
  .table-item {
    margin-bottom: 40px;
  }

  .table-title {
    padding: 30px 0;
    border-radius: 20px 20px 0 0;
    text-align: center;
    font-weight: 700;
  }

  .price {
    &.st {
      background-color: #edf2f6;
    }

    &.bz {
      background-color: #f5eaf2;
    }

    &.pr {
      background-color: #f7dfc3;
    }
  }

  .st {
    .table-title {
      background-color: #edf2f6;
    }

    .table-inner {
      background: rgba(237,242,246,.15);
    }
  }

  .bz {
    .table-title {
      background-color: #f5eaf2;
    }

    .table-inner {
      background-color: rgba(245, 234, 242, 0.15)
    }
  }
  .pr {
    .table-title {
      background-color: #f7dfc3;
    }

    .table-inner {
      background-color: rgba(247, 223, 195, 0.15)
    }
  }

  .row {
    border: 1px solid #edf2f6;
    border-top: 0;
    text-align: center;

    .button {
      margin: 0 auto;
    }

   .props {
     font-weight: 700;
   }

    div {
      padding: 13px 10px;
    }

    &:last-child {
      padding: 20px;
    }
  }
}

.shadow-text {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 5px black;
  margin-top: 40px;
  margin-bottom: 50px;
}

.section-11 {
  background-color: #f5eaf2;
  padding-top: 45px;
  padding-bottom: 100px;

  .inner {
    display: flex;
    max-width: 920px;
  }

  .little-title {
    font-size: 24px;
  }

  .big-title {
    font-size: 47px;
    font-weight: 700;
    margin-bottom: 35px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .author-image {
    width: 48%;
    max-width: 430px;
  }

  .info-about {
    position: relative;
    margin-top: 125px;
    width: 50%;
    margin-left: 35px;
  }

  .cake {
    position: absolute;
    top: -92px;
    right: 53%;
    transform: rotate(-7deg);
    width: 95px;
  }
}

.group-block {
  min-height: 672px;
  background: url(../img/group-bg.png) no-repeat center;
  background-size: cover;
  max-width: 908px;
  margin: -133px auto -400px;
  z-index: 10;
  position: relative;
  padding: 55px 40px 70px;

  .inner-block {
    width: 435px;

    .g-title {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 30px;
    }

    .button {
      width: 305px;
    }

    .text {
      margin-bottom: 30px;

      span {
        font-style: italic;
        font-weight: 700;
      }
    }
  }
}

.footer {
  min-height: 760px;
  background: url("../img/footer-bg.jpg") no-repeat center;
  background-size: cover;
  padding-top: 290px;
  padding-bottom: 30px;
  text-align: center;

  .bottom-link {
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.2px;
    &:hover {
      color: $text-color-red
    }
  }

  .footer-link {
    display: block;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    transition: .3s;

    &:hover {
      color: $text-color-red
    }
  }
}

.footer-social {
  max-width: 500px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin: 55px auto 155px;

  .social-item {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    transition: .3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.footer-bot-image {
  width: 50px;
  display: block;
  margin: 25px auto 0;
}

.modal-inner {
  width: 90%;
  max-width: 889px;
  min-height: 258px;
  border-radius: 20px;
  background-color: #f27a86;;
  padding: 40px 30px;
  color: white;
  position: fixed;
  left: -100%;
  top: 50%;
  z-index: 100;
  transition: .3s;
  transform: translate(-50%, -50%);

  &.active {
    left: 50%;
  }

  .info-text {
    color: #ffffff;
    font-size: 24px;
    max-width: 680px;
    margin: 0 auto 30px;
    text-align: center;
  }

  .input-text {
    width: 250px;
    height: 72px;
    line-height: 72px;
    background: transparent;
    border-radius: 36px;
    border: 3px solid #ffffff;
    outline: none;
    padding: 0 15px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    transition: .3s;

    &::placeholder {
      color: #ffffff;
      font-size: 18px;
    }
  }

  .submit {
    width: 305px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #ffffff;
    border: 0;
    outline: none;
    padding: 0 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: .3s;
    display: block;
    margin: 50px auto 10px;

    &:hover {
      background: #F0F0F0;
    }
  }

  .fields {
    display: flex;
    justify-content: space-between;
  }
}

.modal-open {
  position: fixed;
  display: block;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);

  .modal-inner {
    left: 50%;
  }
}

.section-12 {
  padding-top: 380px;

  .title {
    max-width: 460px;
    text-align: center;
    margin: 0 auto;
  }
}

.work-items {
  display: flex;
  flex-wrap: wrap;
  max-width: 850px;
  margin: 80px auto 50px;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 260px;
    height: 260px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.section-13 {
  padding-top: 30px;
  padding-bottom: 75px;
}

.blog-slider__container {
  max-width: 100%;
  position: relative;
  margin: auto;
  height: 300px;
  .blogSlides img {
    object-fit: cover;
    width: 100%;
  }
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  opacity: 0.8;
}

.next {
  right: -125px;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: -125px;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  opacity: 1;
}

.blogSlider__dots {
  text-align: center;
  &--dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
}

.active,
.blogSlider__dots--dot:hover {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

@keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

.images-slider {
  margin: 0 auto;
  width: 290px;
  height: 593px;
  background: url("../img/mobile.png") no-repeat center;
  background-size: cover;
  padding: 16px;
}

.blogSlides {
  height: 100%;
  align-items: center;
}