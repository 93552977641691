@charset "UTF-8";
body * {
  box-sizing: border-box; }

body {
  font-family: "Circle", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #685c5c;
  background-color: #ffffff;
  margin: 0;
  line-height: 1.2; }

strong {
  font-weight: 700; }

.text-red {
  color: #f27a86; }

.button {
  display: block;
  width: 270px;
  height: 72px;
  line-height: 72px;
  border-radius: 36px;
  background-color: #f27a86;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  transition: .3s; }
  .button:hover {
    background-color: #f2586f; }

.mobile-only {
  display: none; }

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 10px; }

.title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  line-height: 1; }
  .title span {
    font-style: italic; }

.header {
  background: #f5eaf2; }

.section-01 {
  position: relative; }
  .section-01 .top-block {
    display: flex;
    padding: 80px 0 0; }
  .section-01 .info-block {
    margin-left: -15px;
    max-width: 480px;
    padding-top: 83px; }
  .section-01 .info-inner {
    font-weight: 700;
    font-size: 24px;
    padding: 45px 15px; }
    .section-01 .info-inner span {
      color: #f27a86; }
  .section-01 .image-block {
    position: relative; }
    .section-01 .image-block .author-image {
      display: block; }
    .section-01 .image-block .top {
      right: 53px;
      position: absolute;
      top: 27px;
      transform: rotate(-7deg);
      width: 103px; }
    .section-01 .image-block .bottom {
      left: 75px;
      position: absolute;
      top: 215px;
      width: 121px; }
  .section-01 .date-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 45px; }
    .section-01 .date-list .item {
      margin-right: 15px;
      display: flex;
      align-items: center; }
      .section-01 .date-list .item:first-child {
        margin-right: 35px; }
      .section-01 .date-list .item img {
        display: inline-block;
        margin-right: 6px; }
  .section-01 .bottom-block {
    background: url("../img/header-bt-bg.png") no-repeat center;
    width: 553px;
    height: 126px;
    padding: 29px 97px;
    text-align: center;
    position: absolute;
    bottom: -62px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-style: italic;
    line-height: 1;
    -webkit-filter: drop-shadow(2px 15px 9px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(2px 15px 9px rgba(0, 0, 0, 0.25)); }

.svg-shadow {
  -webkit-filter: drop-shadow(2px 7px 9px rgba(242, 88, 111, 0.7));
  filter: drop-shadow(2px 7px 9px rgba(242, 88, 111, 0.7)); }

.main-title {
  font-size: 72px;
  font-weight: 700;
  line-height: 60px; }
  .main-title span {
    font-style: italic;
    color: #f27a86;
    display: block;
    margin-left: 25px; }

.section-02 {
  padding-top: 129px;
  padding-bottom: 140px; }
  .section-02 .list {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px; }
    .section-02 .list .item {
      width: 30%;
      max-width: 210px; }
    .section-02 .list .item-icon {
      width: 187px;
      height: 186px; }
    .section-02 .list .item-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 20px; }
    .section-02 .list .item-info {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px; }
  .section-02 .second {
    margin-top: 90px; }
  .section-02 .secondary-list {
    display: flex;
    justify-content: space-between;
    max-width: 706px;
    margin: 8px auto 80px; }
    .section-02 .secondary-list .item {
      width: 45%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .section-02 .secondary-list .item-icon {
        width: 204px;
        height: 152px;
        margin-bottom: 24px; }
      .section-02 .secondary-list .item-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: center; }

.lana-del-block {
  max-width: 886px;
  background: url("../img/lana-block-bg.jpg") no-repeat center;
  background-size: cover;
  min-height: 549px;
  padding: 70px 65px 50px;
  box-shadow: 3px 14px 24px #e8d0b4;
  position: relative;
  border-radius: 20px;
  margin-bottom: -440px; }
  .lana-del-block .cake {
    position: absolute;
    left: 36%;
    top: -42px;
    transform: rotate(-7deg);
    width: 103px; }
  .lana-del-block .lana-img {
    position: absolute;
    right: 0;
    bottom: 0; }
  .lana-del-block .inner-info {
    max-width: 320px;
    position: relative; }
    .lana-del-block .inner-info:after {
      content: 'Лана Дель Рей';
      position: absolute;
      bottom: 0;
      right: -145px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      display: inline-block; }
  .lana-del-block .subtitle {
    font-size: 36px;
    font-weight: 700;
    font-style: italic;
    line-height: 36px;
    margin-bottom: 35px; }
  .lana-del-block .info {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 305px;
    margin-bottom: 30px; }

.section-03 {
  background-color: #f5eaf2;
  padding-top: 375px;
  padding-bottom: 90px; }
  .section-03 .title {
    text-align: left;
    margin-bottom: -30px; }
    .section-03 .title span {
      font-style: normal; }
    .section-03 .title > span {
      display: block; }
  .section-03 .list-info {
    background: url("../img/bg-03.png") no-repeat center;
    background-size: contain;
    min-height: 610px;
    max-width: 844px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between; }
  .section-03 .info-column {
    padding-top: 110px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .section-03 .button {
    margin: 0 auto; }

.about-course {
  margin-top: 40px;
  margin-bottom: 114px; }
  .about-course .course-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px; }
  .about-course .course-column {
    position: relative;
    width: 49%; }
    .about-course .course-column.img {
      max-width: 414px;
      height: 280px; }
    .about-course .course-column .main-img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 12px; }
  .about-course .subtitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    padding-left: 14px;
    padding-top: 20px; }
  .about-course .info {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 45px;
    margin-bottom: 30px; }
    .about-course .info:last-child {
      margin-bottom: 0; }
    .about-course .info:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -8px;
      left: 5px;
      filter: drop-shadow(2px 4px 3px rgba(242, 88, 111, 0.7)); }
  .about-course .cake {
    right: 0;
    position: absolute;
    top: -35px;
    width: 85px; }
  .about-course .cake-2 {
    right: 11px;
    position: absolute;
    top: -41px;
    width: 90px; }

.section-04 {
  padding-top: 84px; }
  .section-04 .title {
    text-align: left; }

.imagine-block {
  max-width: 911px;
  min-height: 795px;
  background: url("../img/povar-bg.png") no-repeat center;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 29px; }
  .imagine-block .inner-block {
    padding: 65px 40px 95px 90px; }
  .imagine-block .info-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-left: 45px;
    margin-bottom: 30px;
    max-width: 340px; }
    .imagine-block .info-text:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -8px;
      left: 5px;
      filter: drop-shadow(2px 4px 3px rgba(242, 88, 111, 0.7)); }
  .imagine-block .bottom-info {
    font-size: 24px;
    line-height: 1;
    max-width: 411px;
    margin-bottom: 30px; }
  .imagine-block .title {
    text-align: left;
    margin-bottom: 28px; }
  .imagine-block .button {
    width: 305px; }
  .imagine-block .cake {
    left: -31px;
    position: absolute;
    top: 256px;
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
    width: 94px; }
  .imagine-block .cake-2 {
    right: -40px;
    position: absolute;
    bottom: 204px;
    width: 103px; }

.process-block {
  display: flex; }
  .process-block .image {
    margin-left: -100px; }
  .process-block .info-block {
    margin-left: -50px;
    padding-top: 55px; }
  .process-block .subtitle {
    max-width: 429px;
    font-size: 47px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 30px; }
  .process-block .info-text {
    max-width: 390px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 35px; }
    .process-block .info-text span {
      font-weight: 700;
      font-style: italic; }
  .process-block .button {
    width: 305px; }

.section-05 {
  background-color: #f5eaf2;
  padding-top: 68px;
  padding-bottom: 50px; }
  .section-05 .title {
    text-align: left;
    max-width: 840px;
    margin: 0 auto 50px; }

.format-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 840px;
  margin: 0 auto 50px; }

.format-item {
  width: 48%;
  margin-bottom: 45px; }

.format-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 20px; }

.format-image {
  text-align: center;
  min-height: 185px;
  margin-bottom: 10px; }

.format-info {
  line-height: 24px; }

.certificate-block {
  max-width: 889px;
  min-height: 358px;
  border-radius: 20px;
  background-color: #edf2f6;
  display: flex;
  align-items: center;
  padding: 57px 57px 6px;
  justify-content: space-between;
  margin-bottom: -225px; }
  .certificate-block .info-text {
    max-width: 313px;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-top: -62px; }
    .certificate-block .info-text span {
      font-style: italic; }

.section-06 {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 250px; }
  .section-06 .title {
    text-align: left;
    max-width: 862px;
    margin: 0 auto; }

.master-class {
  display: flex;
  justify-content: space-between;
  max-width: 862px;
  margin: 80px auto 135px; }

.master-item {
  width: 31%;
  max-width: 215px; }

.master-image {
  min-height: 173px;
  margin-bottom: 15px;
  text-align: center; }

.master-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 25px; }

.master-text {
  line-height: 24px; }

.package-block {
  display: flex;
  min-height: 200px;
  border-radius: 20px;
  background-color: #edf2f6;
  justify-content: space-between;
  max-width: 886px;
  margin: 0 auto;
  padding: 50px 75px;
  position: relative;
  align-items: center; }
  .package-block .cake {
    width: 102px;
    position: absolute;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%); }
  .package-block .button {
    width: 294px; }

.package-text {
  max-width: 325px;
  font-size: 24px;
  line-height: 1; }

.section-07 {
  margin-top: 70px; }
  .section-07 .title {
    text-align: left;
    margin-bottom: 15px; }

.lessons-list {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 125px; }
  .lessons-list .lessons-title {
    display: flex;
    align-items: center;
    margin-top: 47px; }
    .lessons-list .lessons-title.big-text {
      align-items: flex-start;
      flex-direction: column; }
      .lessons-list .lessons-title.big-text .counter {
        margin-bottom: 16px; }
      .lessons-list .lessons-title.big-text .text {
        margin: 0; }
  .lessons-list .counter {
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    background-color: #fdc878;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700; }
  .lessons-list .lessons-item {
    max-width: 380px;
    width: 49%; }
    .lessons-list .lessons-item:nth-child(1) {
      order: 1; }
    .lessons-list .lessons-item:nth-child(2) {
      order: 3; }
    .lessons-list .lessons-item:nth-child(3) {
      order: 5; }
    .lessons-list .lessons-item:nth-child(4) {
      order: 10; }
    .lessons-list .lessons-item:nth-child(5) {
      order: 2; }
    .lessons-list .lessons-item:nth-child(6) {
      order: 4; }
    .lessons-list .lessons-item:nth-child(7) {
      order: 6; }
    .lessons-list .lessons-item:nth-child(8) {
      order: 12; }
  .lessons-list .text {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 15px;
    display: inline-block; }
    .lessons-list .text .small {
      font-weight: 400; }
  .lessons-list .inner-list > li {
    position: relative;
    padding-left: 40px;
    margin-top: 30px;
    line-height: 24px;
    max-width: 370px; }
    .lessons-list .inner-list > li:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -10px;
      left: -2px;
      filter: drop-shadow(2px 4px 3px rgba(242, 88, 111, 0.7)); }

.section-08 {
  background-color: #f5eaf2;
  padding-top: 75px;
  padding-bottom: 175px;
  position: relative; }
  .section-08 .top-cake {
    width: 102px;
    position: absolute;
    top: -33px;
    left: 55%;
    transform: translateX(-50%); }
  .section-08 .title {
    text-align: left;
    margin-bottom: 55px; }

.bonus-list {
  display: flex;
  max-width: 800px;
  justify-content: space-between; }
  .bonus-list .subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 40px; }
  .bonus-list .inner-list > li {
    position: relative;
    padding-left: 36px;
    margin-bottom: 15px;
    line-height: 24px;
    max-width: 340px; }
    .bonus-list .inner-list > li:before {
      content: '';
      width: 39px;
      height: 39px;
      background: url("../img/svg-09.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      top: -10px;
      left: -7px;
      filter: drop-shadow(2px 4px 3px rgba(242, 88, 111, 0.7)); }

.present-block {
  max-width: 889px;
  min-height: 258px;
  border-radius: 20px;
  background-color: #edf2f6;
  margin: 25px auto -300px;
  display: flex;
  align-items: center;
  padding: 35px 50px 50px;
  justify-content: space-between; }
  .present-block .inner-text {
    max-width: 328px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px; }
  .present-block .button {
    width: 238px; }

.section-09 {
  padding-top: 60px;
  padding-bottom: 60px; }
  .section-09 .title {
    text-align: left;
    margin-bottom: 78px; }
  .section-09 .button {
    width: 305px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto; }

.maker-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 850px;
  margin: 0 auto; }

.maker-item {
  width: 30%;
  max-width: 220px;
  margin-bottom: 75px;
  padding-right: 20px; }

.maker-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 25px 0 20px; }

.maker-text {
  line-height: 24px; }

.maker-image {
  border-radius: 25px;
  width: 200px;
  height: 200px;
  overflow: hidden; }
  .maker-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.section-10 {
  padding-top: 70px;
  position: relative;
  padding-bottom: 50px; }
  .section-10 .title {
    text-align: left;
    margin-bottom: 44px; }
  .section-10 .cake-1 {
    right: 20%;
    position: absolute;
    top: 27px;
    transform: rotate(-7deg);
    width: 103px; }

.section-10 .table .row {
  display: flex;
  justify-content: space-between; }

.section-10 .table .row.head .col {
  text-align: center;
  padding: 0 10px 5px; }
  .section-10 .table .row.head .col div {
    padding: 30px 0;
    border-radius: 20px 20px 0 0; }
  .section-10 .table .row.head .col.st div {
    background-color: #edf2f6; }
  .section-10 .table .row.head .col.bz div {
    background-color: #f5eaf2; }
  .section-10 .table .row.head .col.pr div {
    background-color: #f7dfc3; }

.section-10 .table .row.foot .col {
  text-align: center;
  padding: 0 10px 5px; }
  .section-10 .table .row.foot .col div {
    padding: 30px 0;
    border-radius: 0 0 20px 20px; }
  .section-10 .table .row.foot .col.st div {
    background-color: #edf2f6; }
  .section-10 .table .row.foot .col.bz div {
    background-color: #f5eaf2; }
  .section-10 .table .row.foot .col.pr div {
    background-color: #f7dfc3; }

.section-10 .table .row.body .col {
  text-align: center;
  padding: 5px 10px; }
  .section-10 .table .row.body .col.big {
    text-align: left; }
  .section-10 .table .row.body .col div {
    height: 100%;
    min-height: 148px;
    line-height: 148px; }
  .section-10 .table .row.body .col.st div {
    background-color: rgba(237, 242, 246, 0.15); }
  .section-10 .table .row.body .col.bz div {
    background-color: rgba(245, 234, 242, 0.15); }
  .section-10 .table .row.body .col.pr div {
    background-color: rgba(247, 223, 195, 0.15); }
  .section-10 .table .row.body .col .table-txt-cen {
    position: absolute;
    width: 200%;
    left: -100px;
    top: 40%; }
  .section-10 .table .row.body .col .txt-2-col {
    position: absolute;
    width: 100%;
    left: -100px;
    top: 43%; }

.section-10 .table .col {
  width: 25%;
  position: relative; }
  .section-10 .table .col.big {
    width: 219px; }

.section-10 .table .button {
  width: 100%; }

.section-10 .table .row.body.buttons .col div {
  background: transparent;
  min-height: 0; }

.table-mobile .table-item {
  margin-bottom: 40px; }

.table-mobile .table-title {
  padding: 30px 0;
  border-radius: 20px 20px 0 0;
  text-align: center;
  font-weight: 700; }

.table-mobile .price.st {
  background-color: #edf2f6; }

.table-mobile .price.bz {
  background-color: #f5eaf2; }

.table-mobile .price.pr {
  background-color: #f7dfc3; }

.table-mobile .st .table-title {
  background-color: #edf2f6; }

.table-mobile .st .table-inner {
  background: rgba(237, 242, 246, 0.15); }

.table-mobile .bz .table-title {
  background-color: #f5eaf2; }

.table-mobile .bz .table-inner {
  background-color: rgba(245, 234, 242, 0.15); }

.table-mobile .pr .table-title {
  background-color: #f7dfc3; }

.table-mobile .pr .table-inner {
  background-color: rgba(247, 223, 195, 0.15); }

.table-mobile .row {
  border: 1px solid #edf2f6;
  border-top: 0;
  text-align: center; }
  .table-mobile .row .button {
    margin: 0 auto; }
  .table-mobile .row .props {
    font-weight: 700; }
  .table-mobile .row div {
    padding: 13px 10px; }
  .table-mobile .row:last-child {
    padding: 20px; }

.shadow-text {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 5px black;
  margin-top: 40px;
  margin-bottom: 50px; }

.section-11 {
  background-color: #f5eaf2;
  padding-top: 45px;
  padding-bottom: 100px; }
  .section-11 .inner {
    display: flex;
    max-width: 920px; }
  .section-11 .little-title {
    font-size: 24px; }
  .section-11 .big-title {
    font-size: 47px;
    font-weight: 700;
    margin-bottom: 35px; }
  .section-11 .text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px; }
  .section-11 .author-image {
    width: 48%;
    max-width: 430px; }
  .section-11 .info-about {
    position: relative;
    margin-top: 125px;
    width: 50%;
    margin-left: 35px; }
  .section-11 .cake {
    position: absolute;
    top: -92px;
    right: 53%;
    transform: rotate(-7deg);
    width: 95px; }

.group-block {
  min-height: 672px;
  background: url(../img/group-bg.png) no-repeat center;
  background-size: cover;
  max-width: 908px;
  margin: -133px auto -400px;
  z-index: 10;
  position: relative;
  padding: 55px 40px 70px; }
  .group-block .inner-block {
    width: 435px; }
    .group-block .inner-block .g-title {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 30px; }
    .group-block .inner-block .button {
      width: 305px; }
    .group-block .inner-block .text {
      margin-bottom: 30px; }
      .group-block .inner-block .text span {
        font-style: italic;
        font-weight: 700; }

.footer {
  min-height: 760px;
  background: url("../img/footer-bg.jpg") no-repeat center;
  background-size: cover;
  padding-top: 290px;
  padding-bottom: 30px;
  text-align: center; }
  .footer .bottom-link {
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.2px; }
    .footer .bottom-link:hover {
      color: #f27a86; }
  .footer .footer-link {
    display: block;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    transition: .3s; }
    .footer .footer-link:hover {
      color: #f27a86; }

.footer-social {
  max-width: 500px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin: 55px auto 155px; }
  .footer-social .social-item {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    transition: .3s; }
    .footer-social .social-item:hover {
      opacity: 0.8; }

.footer-bot-image {
  width: 50px;
  display: block;
  margin: 25px auto 0; }

.modal-inner {
  width: 90%;
  max-width: 889px;
  min-height: 258px;
  border-radius: 20px;
  background-color: #f27a86;
  padding: 40px 30px;
  color: white;
  position: fixed;
  left: -100%;
  top: 50%;
  z-index: 100;
  transition: .3s;
  transform: translate(-50%, -50%); }
  .modal-inner.active {
    left: 50%; }
  .modal-inner .info-text {
    color: #ffffff;
    font-size: 24px;
    max-width: 680px;
    margin: 0 auto 30px;
    text-align: center; }
  .modal-inner .input-text {
    width: 250px;
    height: 72px;
    line-height: 72px;
    background: transparent;
    border-radius: 36px;
    border: 3px solid #ffffff;
    outline: none;
    padding: 0 15px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    transition: .3s; }
    .modal-inner .input-text::placeholder {
      color: #ffffff;
      font-size: 18px; }
  .modal-inner .submit {
    width: 305px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #ffffff;
    border: 0;
    outline: none;
    padding: 0 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: .3s;
    display: block;
    margin: 50px auto 10px; }
    .modal-inner .submit:hover {
      background: #F0F0F0; }
  .modal-inner .fields {
    display: flex;
    justify-content: space-between; }

.modal-open {
  position: fixed;
  display: block;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6); }
  .modal-open .modal-inner {
    left: 50%; }

.section-12 {
  padding-top: 380px; }
  .section-12 .title {
    max-width: 460px;
    text-align: center;
    margin: 0 auto; }

.work-items {
  display: flex;
  flex-wrap: wrap;
  max-width: 850px;
  margin: 80px auto 50px;
  justify-content: space-between;
  align-items: center; }
  .work-items .item {
    width: 260px;
    height: 260px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 30px; }
    .work-items .item img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.section-13 {
  padding-top: 30px;
  padding-bottom: 75px; }

.blog-slider__container {
  max-width: 100%;
  position: relative;
  margin: auto;
  height: 300px; }
  .blog-slider__container .blogSlides img {
    object-fit: cover;
    width: 100%; }

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  opacity: 0.8; }

.next {
  right: -125px;
  border-radius: 3px 0 0 3px; }

.prev {
  left: -125px;
  border-radius: 3px 0 0 3px; }

.prev:hover,
.next:hover {
  opacity: 1; }

.blogSlider__dots {
  text-align: center; }
  .blogSlider__dots--dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease; }

.active,
.blogSlider__dots--dot:hover {
  background-color: #717171; }

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s; }

@-webkit-keyframes fade {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: .4; }
  to {
    opacity: 1; } }

.images-slider {
  margin: 0 auto;
  width: 290px;
  height: 593px;
  background: url("../img/mobile.png") no-repeat center;
  background-size: cover;
  padding: 16px; }

.blogSlides {
  height: 100%;
  align-items: center; }
